/* .container {
  max-width: 450px;
  align-items: center;
} */

.container {
  margin-top: 100px;
}

/* Mobile View */
@media screen and (max-width: 720px) {
  .container {
    margin-top: 0px;
  }
}


@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

* {
  box-sizing: border-box;
}

body {
  /* background: #a8bbd5 !important ; */
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  height: 100%;

  /* background: rgb(238, 174, 202); */
  /* background: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgba(208, 162, 196, 1) 19%, rgba(180, 151, 191, 1) 35%, rgba(114, 124, 179, 1) 58%, rgba(55, 117, 201, 1) 83%, rgba(25, 88, 162, 1) 100%); */

  background: rgb(18,9,42);
  background: linear-gradient(41deg, rgba(18,9,42,1) 0%, rgba(25,94,99,1) 23%, rgba(14,55,59,1) 40%, rgba(41,96,101,1) 58%, rgba(63,102,105,1) 80%);

  background: #f4f7fb!important;

}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}


/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #0A283E #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  /* background-color: #0A283E ; */
  border-radius: 10px;
  border: 3px solid #ffffff;
  background-image: linear-gradient(0deg, rgba(154, 224, 230, 1) 0%, rgba(102, 175, 180, 1) 26%, rgb(50, 168, 179) 100%);
}