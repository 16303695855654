:root {
    --color-white: #FFFFFF;
    --color-black: #000000;
    --color-tangaroa: #0A253E;
    --color-light-gray: #F2F4F9;
    --color-light-gray-200: #E5EAF5;

    --color-persian-green: #13ac95;

    --radius-10: 10px;
    --radius-20: 20px;

    --primary-color: var(--color-tangaroa);
    --secondary-color: var(--color-white);

    --text-primary: #27232a;
    --text-secondary: #677a89;
}

.text-primary {
    color: #27232a !important;
    color: var(--text-primary) !important;
}

.text-secondary {
    color: #677a89 !important;
    color: var(--text-secondary) !important;
}


.bg-top-navbar {
    background-color: #FFFFFF;
    background-color: var(--color-white);
    border-color: rgba(231, 231, 231, 0);
    box-shadow: 0 0 2px #c8c6c6;
}

.row {
    margin: 0px;
}

p {
    margin-bottom: 0rem;
}

.bg-light-gray-200 {
    background-color: #E5EAF5;
    background-color: var(--color-light-gray-200);
}




/*
*
* ==========================================
* CUSTOM LOGIN CLASSES
* ==========================================
*
*/
.login,
.image {
    min-height: 100vh;
}

.bg-image {
    /* background-image: url(../../../assets/images/login/laptop.png); */
    background-repeat: no-repeat;
    /* background-size: contain; */
    background-size: cover;
    background-position: center center;
    background-color: #046380;
    color: #FFFFFF;
}

.btn-login {
    color: #fff;
    background-color: #046380;
    border-color: #046380;
}

.btn-login:hover {
    color: #fff;
    background-color: #046380;
    border-color: #046380;
}

.login-text {
    text-align: center;
    font-size: 2rem;
    line-height: 1.2
}

/*
*
* ==========================================
* END CUSTOM LOGIN CLASSES
* ==========================================
*
*/


.page-container {
    background: red;
    padding: 1rem;
    border-radius: 10px;
    /* height: 100%; */
    background-color: #fff !important;
    box-shadow: 0 7px 7px rgba(0, 0, 0, 0.18);
    height: 98vh;
    width: 98vw;
}

.detail-container {
    background-color: #FFFFFF;
    background-color: var(--color-white);
    border-radius: 10px;
    border-radius: var(--radius-10);
    padding: 1rem;
}


.heading-card {
    border-radius: 10px;
    border: 1px solid #E6EAF3;
    background: #FFF;
    box-shadow: 0px 4px 14px 0px rgba(205, 212, 226, 0.40);
    padding: 0.5rem;
}

.profile-card {
    border-radius: 10px;
    border: 1px solid #E6EAF3;
    background: #FFF;
    box-shadow: 0px 4px 14px 0px rgba(205, 212, 226, 0.40);
    padding: 1rem;
    background: rgb(154, 224, 230);
    background: linear-gradient(41deg, rgba(154, 224, 230, 1) 0%, rgba(74, 176, 184, 1) 26%);
}


.profile-card .profile-status {
    background-color: aquamarine;
    padding: 2px 25px;
    width: -webkit-max-content;
    width: max-content;
    border-radius: 25px;
}

.profile-card-avatar {
    -webkit-transform: none;
            transform: none;
    left: 20px;
    width: 80px;
    height: 80px;
    background: #e0e0e0;
    border-radius: 100%;
}

.profile-card-fullname {
    bottom: 18px;
    font-size: 22px;
}

.profile-card-jobtitle {
    bottom: 16px;
    letter-spacing: 1px;
    font-size: 16px;
}

/* .c-btn {
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    color: var(--color-white);
    padding: 10px 14px;
    gap: 10px;
    background: var(--color-charlotte);
    border-radius: 6px;
    border: none;
} */

.dark-btn {
    border: 1px solid #0A253E;
    border: 1px solid var(--color-tangaroa);
    color: #FFFFFF;
    color: var(--color-white);
    background: #0A253E;
    background: var(--color-tangaroa);
}



.document-wrapper {
    border-radius: 10px;
    border: 1px solid #E6EAF3;
    background: #FFF;
    box-shadow: 0px 4px 14px 0px rgba(205, 212, 226, 0.40);
    padding: 1rem;
}


.flyout-list-group-item-card-container {
    border: 1px solid rgba(191, 199, 219, 0.3);
    border-radius: 10px;
    overflow: auto;
}

.flyout-list-group-item-card {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    background: var(--color-white);
    padding: 1rem;
}


.flyout-list-group-item-card.active {
    background-color: var(--color-alice-blue) !important;
}

.flyout-list-group-item-card.system {
    background-color: var(--color-pattens-blue) !important;
}

.flyout-list-group-item-card .icon-img {
    width: 26px;
    height: 26px;
}

.flyout-list-group-item-card .sub-icon {
    padding-top: 5px;
    padding-bottom: 5px;
    /* padding-right: 10px; */
    /* padding-left: 10px; */
    width: 48px;
    height: 48px;
    background: #fff3f7;
    border-radius: 10px;
}

.flyout-list-group-item-card .sub-icon img {
    width: 100%;
}

.flyout-list-group-item-card .file-size-text {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #0A253E;
    color: var(--color-tangaroa);
    opacity: 0.5;
    margin-bottom: 0.1rem;
    text-align: center;
}

.flyout-list-group-item-card .icon-btn {
    width: 18px;
    height: 18px;
    color: #0A253E;
    color: var(--color-tangaroa);
    opacity: 0.6;
}

.flyout-list-group-item-card .icon-btn:hover {
    color: #0A253E;
    color: var(--color-tangaroa);
    opacity: 1;
}

.flyout-list-group-item-card .bold-text {
    font-weight: 500;
    font-size: 14px;
    color: #0A253E;
    color: var(--color-tangaroa);
}

.flyout-list-group-item-card .light-text {
    font-weight: 400;
    font-size: 12px;
    text-align: right;
    color: #0A253E;
    color: var(--color-tangaroa);
    opacity: 0.4;
    padding-right: 0.5rem;
    width: auto;
}

.flyout-list-group-item-card-footer {
    background: #FFFFFF;
    background: var(--color-white);
    /* display: flex;
    align-items: center;
    padding: 1rem; */
}

.flyout-list-group-item-card .file-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #0A253E;
    color: var(--color-tangaroa);
}

.flyout-list-group-item-card .file-by {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #0A253E;
    color: var(--color-tangaroa);
    opacity: 0.4;
}


.timeline-card {
    background-color: #F2F4F9;
    background-color: var(--color-light-gray);
    border-radius: 10px;
    border-radius: var(--radius-10);
}

.timeline-card .title {
    font-size: 20px;
    font-weight: 600;
    color: #27232a;
    color: var(--text-primary);
}

.timeline-card .sub-title {
    font-size: 14px;
    font-weight: 400;
    color: #677a89;
    color: var(--text-secondary);
}

.timeline-card .h-line {
    border: none;
    border-top: 1px dashed #C0CAE2;
}


.timeline-card .badge {
    padding: 11px 22px;
    color: #27232a;
    color: var(--text-primary);
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
}

.badge-blue {
    background-color: rgba(45, 149, 240, 0.2);
}

.badge-blue i {
    color: rgba(45, 149, 240, 1);
}

.badge-yellow {
    background-color: rgba(216, 166, 65, 0.2);
}

.badge-yellow i {
    color: rgba(216, 166, 65, 1);
}

.badge-purple {
    background-color: rgb(150, 97, 210, 0.2);
}

.badge-purple i {
    color: rgb(150, 97, 210, 1);
}

.badge-indigo-blue {
    background-color: rgba(71, 64, 199, 0.2);
}

.badge-indigo-blue i {
    color: rgba(71, 64, 199, 1);
}

.note {
    color: #677a89;
    color: var(--text-secondary);
    font-size: 14px;
    font-weight: 400;
}



.table-wrapper {
    overflow: hidden;
    background-color: #FFFFFF;
    background-color: var(--color-white);
    box-shadow: 0 1px 5px rgba(0,0,0,.05);
    border-radius: 10px;
    border-radius: var(--radius-10);
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.table-container {
    background-color: #FFFFFF;
    background-color: var(--color-white);
    padding: 1rem;
    /* height: 370px; */
    /* overflow: auto; */
}

.table-header {
    background: rgba(235,238,245,.8); 
    padding: 1rem;
    box-shadow: 0 4px 4px rgba(0,0,0,.25);
    background-repeat: no-repeat;
    background-position: 100%;
}

.table-header-image {
    height: 100px;
}




.table-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 1.5rem;
}

.dashboard-table{
    height: 390px;
}

.dashboard-table table tbody{
    font-size: 14px;
}



/* Dashboard Card */
.dashboard-card {

    position: relative;
    display: -webkit-box;
    /* flex-direction: column; */
    min-width: 0;
    word-wrap: break-word;
    background-color: #FFFFFF;
    background-color: var(--secondary-color);
    background-color: #E5EAF5;
    background-color: var(--color-light-gray-200);
    background-clip: border-box;
    /* border: 1px solid rgba(0, 0, 0, .125); */


    border-radius: 10px;


    border-radius: var(--radius-10);
    padding: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0px 3px 6px rgba(150, 161, 169, 0.1);
}

.dashboard-card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.dashboard-card-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #0A253E;
    color: var(--color-tangaroa);
}

.dashboard-card-number {
    font-size: 20px;
    font-weight: 800;
}

.dashboard-card-sub-number {
    margin-left: 3%;
    font-size: 12px;
    font-weight: 400;
    width: -webkit-max-content;
    width: max-content;
    padding: 2px 10px;
    border-radius: 5px;
    border: 1px solid #097421;
    color: #FFFFFF;
    color: var(--color-white);
}

.dashboard-card-circle {
    width: 66px !important;
    height: 66px !important;
    border-radius: 50%;
    background-color: #E5EAF5;
    background-color: var(--color-light-gray-200);
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-card-sub-number-success {
    border: none;
    background-color: #13ac95;
    background-color: var(--color-persian-green);
}

.dashboard-card-sub-number-danger {
    border: none;
    background-color: var(--color-sundown);
}

.dashboard-card-info {
    font-size: 14px;
    font-weight: 500;
}

.dashboard-loader {
    height: 30px;
}

/* Dashboard Card End */



/*
*
* ==========================================
*  MODAL CLASSES
* ==========================================
*
*/

.modal {
    display: block !important;
    opacity: 1;
}

.modal-content {
    /* box-shadow: 0 3px 3px -2px rgba(0, 0, 0, .2), 0 3px 4px 0 rgba(0, 0, 0, .14), 0 1px 8px 0 rgba(0, 0, 0, .12) !important; */
    border: none !important;
    border-radius: 10px !important;
    overflow: hidden;
    /* box-shadow: -13px 0px 15px rgba(177, 177, 177, 0.5) !important; */
    box-shadow: 0px 0px 20px 3px rgb(177 177 177 / 50%) !important;
}

.modal-close-button {
    padding: 0;
    /* background-color: transparent; */
    border: 0;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    color: #0A253E !important;
    color: var(--color-tangaroa) !important;

    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: #FFFFFF;
    background: var(--color-white);
    box-shadow: 0px 4px 14px rgb(207 213 226 / 50%);
}

.modal-body {
    /* box-shadow: inset 0 0 15px #000000 !important; */
    padding: 1.5rem !important;
}

.modal-header {
    background-color: #FFFFFF !important;
    background-color: var(--color-white) !important;
    color: #0A253E !important;
    color: var(--color-tangaroa) !important;
    padding: 1.5rem !important;
}

.modal-footer {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}

.modal-header-name {
    color: black !important;
}

.modal-photo {
    margin: auto;
    display: block;
    width: 200px;
    border-radius: 20px;
}

.modal .c-transparent-btn {
    display: flex;
    flex-direction: column;
    background: none;
    border: none;
    align-items: center;
}

.modal .c-transparent-btn img {
    width: 25px;
}

.modal-sub-title {
    color: #0A253E;
    color: var(--color-tangaroa);
    opacity: 0.6;
    font-weight: 400;
    font-size: 14px;
    padding-top: 0.5rem !important;
    padding-right: 1.5rem !important;
}

/*
*
* ==========================================
* END MODAL CLASSES
* ==========================================
*
*/



/* input */

input[type="date"] {
    position: relative;
    /* padding: 10px; */
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
}

input[type="date"]:before {
    /* color: transparent; */
    background: none;
    display: block;
    font-family: 'FontAwesome';
    content: '\f073';
    /* This is the calendar icon in FontAwesome */
    /* width: 15px; */
    /* height: 20px; */
    position: absolute;
    /* top: 12px; */
    right: 20px;
    /* color: inherit; */
}

input[type],
.form-select {
    font-weight: 500;
    color: #0A253E;
    color: var(--color-tangaroa);
}

select {
    background: #FFFFFF;
    background: var(--color-white);
    border: 1px solid var(--color-gray);
    border-radius: 4px;
    padding: 0.3rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}

.label-box {
    background: #FFFFFF;
    background: var(--color-white);
    border: 1px solid var(--color-gray);
    border-radius: 4px;
    padding: 0.3rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}

/* input */


.form-group {
    /* margin-bottom: 0.5rem; */
    padding: 1rem;
}

.field-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #0A253E;
    color: var(--color-tangaroa);
    opacity: 0.6;
}


.field-label-value {
    display: block;
    padding: 0.3rem 0.5rem;
    border-radius: 0.35rem;
    min-height: 2rem;
    background-color: rgba(193, 206, 218, 0.28) !important;
    font-weight: 500;
    color: #0A253E !important;
    color: var(--color-tangaroa) !important;

    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
            appearance: none;
    border-radius: 0.25rem;
}



.c-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #0A253E;
    color: var(--color-tangaroa);
    opacity: 0.6;
}

.c-label.bold {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #0A253E;
    color: var(--color-tangaroa);
    opacity: 1;
}

.c-form-control {
    color: #0A253E;
    color: var(--color-tangaroa);
    background: #FFFFFF;
    background: var(--color-white);
    border: 1px solid #C1CEDA;
    border-radius: 4px;
    padding: 10px;
    font-weight: 500;
    font-size: 15px;
    width: 100%;
}

.c-form-control:focus {
    outline-color: #a9b6c1;
}

.c-input-group {
    margin: auto;
}



.time-input {
    display: flex;
    flex: 1 1;
}

.time-input .control {
    width: 80%;
}

.time-input .control-24HR {
    width: auto;
    flex: 1 1;
}

.time-input .format-selector {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex: 1 1;
    background-color: #E5EAF5;
    background-color: var(--color-light-gray-200);
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.time-input .format-selector .shifter {
    cursor: pointer;
    color: #0A253E;
    color: var(--color-tangaroa);
    opacity: 0.6;
}

.time-input .format-selector .shifter:hover {
    color: #0A253E;
    color: var(--color-tangaroa);
    opacity: 1 !important;
}

.time-input .format-selector .shifter-value {
    color: #0A253E;
    color: var(--color-tangaroa);
    font-weight: bold;
}






/* animate-charcter */
.animate-charcter {
    text-transform: uppercase;
    background-image: linear-gradient(-225deg,
            #231557 0%,
            #44107a 29%,
            #ff1361 67%,
            #fff800 100%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: textclip 2s linear infinite;
            animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 20px;
    font-weight: 900;
}

@-webkit-keyframes textclip {
    to {
        background-position: 200% center;
    }
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}
/* animate-charcter */


.clientportal-text-logo{
    text-transform: uppercase;
    /* background-image: linear-gradient(-225deg,
            #231557 0%,
            #44107a 29%,
            #ff1361 67%,
            #fff800 100%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite; */
    display: inline-block;
    font-size: 22px;
    font-weight: 900;
    color: #44107a;
}



.chart-container {
    background-color: #FFFFFF;
    background-color: var(--color-white);
    border-radius: 10px;
    border-radius: var(--radius-10);
    padding: 1rem;
    /* height: 370px; */
    /* overflow: auto; */
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    height: 100%;
}

.chat-title {
    color: var(--piprimary-color);
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 1rem;
}







.dashboard-container {
    height: 100%;
    background-color: #FFFFFF;
    background-color: var(--color-white);
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
}

.dashboard-header {
    background: rgb(235 238 245 / 80%);
    padding: 1rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
}

.dashboard-header-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #0A253E;
    color: var(--color-tangaroa);
}

.dashboard-header-sub-title {
    font-weight: 600;
    font-size: 33px;
    line-height: 50px;
    color: #0A253E;
    color: var(--color-tangaroa);
}

.dashboard-body {
    padding: 1rem;
}

.dashboard-section {
    justify-content: space-between !important;
    display: flex !important;
    margin-bottom: 1rem;
}

.dashboard-title {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 28px;
    color: #0A253E;
    color: var(--color-tangaroa);
}

.dashboard-date-text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #0A253E;
    color: var(--color-tangaroa);
    opacity: 0.6;
}

.dashboard-btn {
    font-weight: 400;
    background-color: var(--color-solitude);
}

.dashboard-btn:hover {
    background-color: var(--color-solitude-dark);
}

.dashboard-btn-active {
    font-weight: 600;
    color: #FFFFFF;
    color: var(--color-white);
    background-color: var(--color-charlotte);
}







.rating {
    border: none;
    float: left;
  }
  
  .rating > label {
    color: #90A0A3;
    float: right;
  }
  
  .rating > label:before {
    margin: 5px;
    font-size: 2em;
    font-family: FontAwesome;
    content: "\f005";
    display: inline-block;
  }
  
  .rating > input {
    display: none;
  }
  
  .rating > input:checked ~ label,
  .rating:not(:checked) > label:hover,
  .rating:not(:checked) > label:hover ~ label {
    color: #F79426;
  }
  
  .rating > input:checked + label:hover,
  .rating > input:checked ~ label:hover,
  .rating > label:hover ~ input:checked ~ label,
  .rating > input:checked ~ label:hover ~ label {
    color: #FECE31;
  }





  .people-search-box{
    width: 300px;
  }


  .welcome-card {
    border-radius: 10px;
    border: 1px solid #E6EAF3;
    background: #FFF;
    box-shadow: 0px 4px 14px 0px rgba(205, 212, 226, 0.40);
    padding: 0.5rem;
}

.jobs-container{
    border-radius: 10px;
    border: 1px solid #E6EAF3;
    background: #FFF;
    box-shadow: 0px 4px 14px 0px rgba(205, 212, 226, 0.40);
}

.jobs-container-header, .jobs-container-body{
    padding: 1rem;
}

.jobs-container-header{
    border-bottom: 1px solid #E6EAF3;
}













.default-container {
    border-radius: 10px;
    border: 1px solid #E6EAF3;
    background: #FFF;
    box-shadow: 0px 4px 14px 0px rgba(205, 212, 226, 0.40);
}

